import antiKeyword from "~/utils/update/migrations/0.54.0/modifierAliases/reserved/antiKeyword"
import meltaN from "~/utils/update/migrations/0.54.0/modifierAliases/reserved/meltaN"
import rapidFireN from "~/utils/update/migrations/0.54.0/modifierAliases/reserved/rapidFireN"
import sustainedHits from "~/utils/update/migrations/0.54.0/modifierAliases/reserved/sustainedHits"
import weaponAbilityAliases from "~/utils/update/migrations/0.54.0/modifierAliases/reserved/weaponAbilityAliases"

export default function modifierAliases(modifier) {
  // Returns reserved alias object if modifier description match is found.
  // Otherwise, returns null.

  let returnAlias = null

  // Check weaponAbilityAliases array for a simple match.
  for (const reservedAlias of weaponAbilityAliases) {
    if (reservedAlias.descriptions.includes(modifier.description)) {
      returnAlias = {
        name: reservedAlias.name,
        type: "reserved",
      }
      break
    }
  }

  // If no alias has been found yet, check alias functions.
  if (returnAlias === null) {
    const aliasFunctions = [antiKeyword, meltaN, rapidFireN, sustainedHits]
    for (const aliasFunction of aliasFunctions) {
      const dynamicAlias = aliasFunction(modifier.description)
      if (dynamicAlias) {
        returnAlias = dynamicAlias
        break
      }
    }
  }

  // console.log("returnAlias", returnAlias)
  return returnAlias
}
