export default function migrate_0_38_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          // Update weapon abilities that use the legacy "only" override type.
          if (typeof ability.override !== "undefined") {
            if (
              typeof ability.override.type !== "undefined" &&
              ability.override.type === "only"
            ) {
              ability.override.type = "onlyConditional"
              profile.updateStatus.updated = true
            }
          }
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
