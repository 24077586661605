export default function effectModifyRelative(data) {
  const { effect } = data

  if (effect.data.application && effect.data.option) {
    const app = effect.data.application
    const option = effect.data.option

    // option.operator *may* be updated to "add" or "subtract" so we don't use it in this check.
    // Instead, we get the first word from option.text.
    const optionTextFirstWord = option.text.split(" ")[0]
    if (optionTextFirstWord === "Degrade" || optionTextFirstWord === "Improve") {
      let valueProcessed = option.value
      if (option.value < 0) {
        // Convert negative number to positive number.
        valueProcessed = Math.abs(option.value)
      }
      return `${optionTextFirstWord} ${app.text} by ${valueProcessed}`
    }

    if (option.text === "Halve") {
      return `${option.text} ${app.text}`
    }

    return `${option.text} to ${app.text}`
  }

  return ""
}
