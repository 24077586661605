export default function migrate_0_37_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon) => {
      // Update weapon.type.rangedShots to weapon.attacks
      if (typeof weapon.attacks === "undefined") {
        weapon.attacks = weapon.type.rangedShots
        delete weapon.type.rangedShots
      }

      // Update weapon.type.rangedType to weapon.subType
      if (typeof weapon.subType === "undefined") {
        weapon.subType = weapon.type.rangedType
        delete weapon.type.rangedType
      }

      // Update value of weapon.type & weapon.subType
      if (weapon?.subType === "Melee") {
        weapon.type = "Melee"
      }
      if (weapon?.subType === "Ranged") {
        weapon.type = "Ranged"
      }

      profile.updateStatus.updated = true
    })
  }

  // Return migrated profile.
  return profile
}
