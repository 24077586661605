export default function migrate_0_48_0(profile) {
  // Ensure all profiles have a weaponIdNext property. Default to 1.
  if (typeof profile.weaponIdNext === "undefined") {
    profile.weaponIdNext = 1
  }

  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
