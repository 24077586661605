export default function migrateModifier(modifier) {
  // Update the shape of effects that use the "replace" operator.
  if (modifier?.effect?.data?.operator === "replace") {
    modifier.effect.data.operator = {
      title: "Replace",
      key: "replace",
    }
  }

  return modifier
}
