export default function migrateModifier(modifier) {
  // Migrate dev wounds modifier and any modifier that uses the woundType condition with a key of "devAndMortalWounds".

  // Update dev wounds modifiers to use new mechanic (as of June 2024 core rules update).

  const devWoundsAlias = modifier.aliases.find((alias) => {
    if (alias.name === "Devastating wounds" && alias.type === "reserved") {
      return alias
    }
  })

  if (typeof devWoundsAlias !== "undefined") {
    modifier.effect = {
      text: "Mortal wounds",
      type: "mortalWounds",
      data: {
        attackSequenceEnds: true,
        cap: null,
        matchWeaponDamage: true,
        mortalWoundsValue: null,
        noSpillOver: true,
      },
    }
    modifier.description =
      "Critical wound : mortal wounds equal to weapon damage (attack sequence ends) (no spill over)"
    if (modifier.scope.includes("profile")) {
      modifier.description += " (if attacker)"
    }
  }

  // Update modifier that uses the woundType condition with a key of "devAndMortalWounds"
  //  (use key of "mortalWounds" instead, update modifier description too)

  if (typeof modifier.conditions !== "undefined") {
    return modifier
  }

  const woundTypeCondition = modifier.conditions.find((condition) => {
    if (
      condition.type === "woundType" &&
      condition.data?.woundType.key === "devAndMortalWounds"
    ) {
      return condition
    }
  })

  if (typeof woundTypeCondition !== "undefined") {
    woundTypeCondition.data.woundType.key = "mortalWounds"
    woundTypeCondition.data.woundType.title = "Mortal wounds"
    modifier.description = modifier.description.replace(
      "(devastating & mortal wounds only)",
      "(mortal wounds only)"
    )
  }

  return modifier
}
