import migrateProfile from "~/utils/update/migrations/0.65.2/migrateProfile"

export default function migrate_0_65_2(profile) {
  // Profiles.
  const migratedProfile = migrateProfile(profile)

  // Mark as updated.
  migratedProfile.updateStatus.updated = true

  // Return migrated profile.
  return migratedProfile
}
