import reservedModifierAliases from "~/utils/update/migrations/0.56.0/modifierAliases/reservedModifierAliases"

export default function modifierAliases(modifier) {
  // Returns reserved alias object if modifier description match is found.
  // Otherwise, returns null.

  // Check reservedModifierAliases array for a match.
  for (const reservedAlias of reservedModifierAliases) {
    const alias = reservedAlias(modifier.description)
    if (alias) {
      return {
        name: alias,
        type: "reserved",
      }
    }
  }

  return null
}
