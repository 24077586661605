export default function migrate_0_29_0(profile) {
  // Add the "ws" property if missing.
  if (typeof profile.ws === "undefined") {
    profile.ws = null
    profile.updateStatus.updated = true
  }

  // Add the "strength" property if missing.
  if (typeof profile.strength === "undefined") {
    profile.strength = null
    profile.updateStatus.updated = true
  }

  // Replace legacy "Attacker" role with "Attacker: Ranged".
  if (profile.roles.includes("Attacker")) {
    const index = profile.roles.indexOf("Attacker")
    if (index !== -1) {
      profile.roles[index] = "Attacker: Ranged"
      profile.updateStatus.updated = true
    }
  }

  // Return migrated profile.
  return profile
}
