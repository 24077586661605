function profileAbilitiesProcessed(abilities) {
  // Surfaces a profile ability's attackType & profileRole conditions
  // at the root level for easy access.
  return abilities.map((ability) => {
    if (typeof ability.conditions !== "undefined") {
      if (ability.conditions.length) {
        ability.conditions.forEach((condition) => {
          if (condition.type === "attackType") {
            ability.attackType = condition.data.attackType
          }
          if (condition.type === "profileRole") {
            ability.profileRole = condition.data.profileRole
          }
        })
      }
    }
    return ability
  })
}

export { profileAbilitiesProcessed }
