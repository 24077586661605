export default function migrateProfile(profile) {
  // Ensure that profile.abilitiesSelected is an array, even if empty or non-existent.

  if (
    typeof profile.abilitiesSelected === "undefined" ||
    profile?.abilitiesSelected === null
  ) {
    profile.abilitiesSelected = []
  }

  return profile
}
