export default function migrateProfile(profile) {
  // Re-assign ability IDs for all units.
  profile.abilityIdNext = 1

  profile.units.forEach((unit) => {
    // Re-assign ability IDs for all units.
    if (unit?.abilities?.length) {
      unit.abilities.forEach((ability) => {
        ability.id = profile.abilityIdNext
        profile.abilityIdNext++
      })
    }

    // Ensure all modelTypes have a weapons array.
    unit.modelTypes.forEach((modelType) => {
      if (typeof modelType.weapons === "undefined") {
        modelType.weapons = []
      }
    })
  })

  // Ensure that profile has at least 1 melee and 1 ranged weapon selected
  // if they have any of either.

  let profileHasMeleeWeaponSelected = false
  let profileHasRangedWeaponSelected = false

  if (profile?.weaponsSelected?.melee?.length === 0) {
    for (const unit of profile.units) {
      for (const modelType of unit.modelTypes) {
        if (modelType.weapons.length === 0) {
          continue
        }
        for (const weapon of modelType.weapons) {
          if (weapon.type === "Melee") {
            profile.weaponsSelected.melee.push(weapon.id)
            profileHasMeleeWeaponSelected = true
          }
          if (profileHasMeleeWeaponSelected) {
            break
          }
        }
        if (profileHasMeleeWeaponSelected) {
          break
        }
      }
      if (profileHasMeleeWeaponSelected) {
        break
      }
    }
  }

  if (profile?.weaponsSelected?.ranged?.length === 0) {
    for (const unit of profile.units) {
      for (const modelType of unit.modelTypes) {
        if (modelType.weapons.length === 0) {
          continue
        }
        for (const weapon of modelType.weapons) {
          if (weapon.type === "Ranged") {
            profile.weaponsSelected.ranged.push(weapon.id)
            profileHasRangedWeaponSelected = true
          }
          if (profileHasRangedWeaponSelected) {
            break
          }
        }
        if (profileHasRangedWeaponSelected) {
          break
        }
      }
      if (profileHasRangedWeaponSelected) {
        break
      }
    }
  }

  return profile
}
