import abilityStringToObjectsMap from "~/utils/update/migrations/0.26.0/abilityStringToObjectsMap"

export default function weaponAbilitiesStringsToObjects(profile, weapon) {
  if (typeof weapon.abilities !== "undefined") {
    const replacementWeaponAbilities = []
    // Loop through any weapon abilities.
    weapon.abilities.forEach((ability) => {
      // Legacy weapon abilities were just strings.
      if (typeof ability === "string") {
        console.log("String weapon ability detected:", ability)
        // Match legacy string weapon ability with new object abilities.
        const mapping = abilityStringToObjectsMap.find(
          (item) => item.stringAbility === ability
        )
        if (typeof mapping !== "undefined") {
          mapping.objectAbilities.forEach((abilityObj) => {
            replacementWeaponAbilities.push(abilityObj)
          })
        } else {
          // Flag this ability for removal
          // if there is no equivalent ability object to match it to.
          replacementWeaponAbilities.push({
            remove: true,
          })
        }
      }
    })

    // Replace legacy string abilities with new object abilities.
    if (replacementWeaponAbilities.length) {
      weapon.abilities = replacementWeaponAbilities
      console.log("Replaced with", replacementWeaponAbilities)
      profile.updateStatus.updated = true
    }

    // Loop through weapon abilities and remove any abilities that act as placeholders to flag removal.
    weapon.abilities.forEach((ability, index) => {
      if (typeof ability.remove !== "undefined") {
        weapon.abilities.splice(index, 1)
        profile.updateStatus.updated = true
        console.log("String weapon ability has no object equivalent - removed.")
      }
    })
  }
}
