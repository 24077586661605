export default function migrate_0_24_0(profile) {
  // Add and init the "weaponsSelected" property if missing.
  if (typeof profile.weaponsSelected === "undefined") {
    profile.weaponsSelected = []
    profile.updateStatus.weaponsSelectedInitialised = true
    profile.updateStatus.updated = true
  }

  // Flatten "weaponsSelected" to a single array if still using the legacy nested structure.
  if (typeof profile.weaponsSelected.ranged !== "undefined") {
    profile.weaponsSelected = profile.weaponsSelected.ranged
    profile.updateStatus.updated = true
  }

  // If any of the IDs in "weaponsSelected" do not match any of the IDs in "weapons"
  // then rebuild weaponsSelected.
  if (
    !profile.updateStatus.weaponsSelectedInitialised &&
    profile.weaponsSelected.length
  ) {
    for (const selectedWeaponId of profile.weaponsSelected) {
      const matchingId = profile.weapons.find(
        (weapon) => weapon.id === selectedWeaponId
      )
      if (typeof matchingId === "undefined") {
        // As soon as we find a selected weapon ID with no corresponding weapon ID
        // flag it and break the loop.
        profile.updateStatus.weaponsSelectedInitialised = true
        profile.weaponsSelected = []
        break
      }
    }
  }

  // Return migrated profile.
  return profile
}
