export default function effectModifyRelative(data) {
  const { effect } = data

  if (
    effect.data.application &&
    effect.data.operator &&
    effect.data.relativeValue
  ) {
    const app = effect.data.application
    const operator = effect.data.operator
    const relativeValue = effect.data.relativeValue

    if (["degrade", "divide", "improve"].includes(operator.key)) {
      return `${operator.key} ${app.title} by ${relativeValue}`
    }

    if (operator.key === "add") {
      return `+${relativeValue} to ${app.title}`
    }

    if (operator.key === "subtract") {
      return `-${relativeValue} to ${app.title}`
    }

    // Fallback
    return `${operator.title} ${relativeValue} to ${app.title}`
  }

  return ""
}
