<script setup>
import { computed, defineAsyncComponent, watch } from "vue"
import { useGlobalStore } from "@/stores/global"
import AlertNewVersion from "~/components/Init/AlertNewVersion.vue"
import semverPatch from "semver/functions/patch"

const Init = defineAsyncComponent(() => import("@/components/Init/Init.vue"))

const { $toast } = useNuxtApp()
const store = useGlobalStore()
const runtimeConfig = useRuntimeConfig()

// Check if we're going to be running automated tests.
if (runtimeConfig.public.seedTestData === "true") {
  // Bypass the intro message.
  store.newUser = false
}

useSeoMeta({
  ogImage: "/og-image.png",
  twitterCard: "summary_large_image",
})

const newVersionIsPatch = computed(() => {
  return !!semverPatch(runtimeConfig.public.clientVersion)
})

watch(
  () => store.alertNewVersion,
  () => {
    if (store.alertNewVersion) {
      const timeout = newVersionIsPatch.value ? 5000 : 15000
      $toast.info(
        // `Version ${runtimeConfig.public.clientVersion} released! See the changelog for details.`,
        AlertNewVersion,
        { timeout, toastClassName: "toast--new-version" }
      )
    }
  }
)
</script>

<template>
  <v-app>
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator
      color="linear-gradient(to right, #AB47BC 0%, #2196f3 100%)"
    />
    <Init />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </v-app>
</template>
