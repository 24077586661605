export default function migrate_0_36_0(profile) {
  // Add the "abilities" property if missing.
  if (typeof profile.abilities === "undefined") {
    profile.abilities = []
    profile.updateStatus.updated = true
  }

  // Add the "abilityIdNext" property if missing.
  if (typeof profile.abilityIdNext === "undefined") {
    profile.abilityIdNext = 1
    profile.updateStatus.updated = true
  }

  // Add the "abilitiesSelected" property if missing.
  if (typeof profile.abilitiesSelected === "undefined") {
    profile.abilitiesSelected = []
    profile.updateStatus.updated = true
  }

  // Return migrated profile.
  return profile
}
