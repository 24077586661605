export default function antiKeyword(description) {
  const regex = /^Wound roll of ([2-5])\+ \(unmodified\) : critical wound \(([A-Z]+) only\)$/
  const result = description.match(regex)
  if (result) {
    return {
      name: `Anti-${result[2]} ${result[1]}+`,
      type: "reserved",
    }
  }
  return null
}
