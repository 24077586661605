import migrateProfile from "~/utils/update/migrations/0.64.4/migrateProfile"

export default function migrate_0_64_4(profile) {
  // Profiles.
  const migratedProfile = migrateProfile(profile)

  // Mark as updated.
  migratedProfile.updateStatus.updated = true

  // Return migrated profile.
  return migratedProfile
}
