export default function migrateProfile(profile) {
  // Ensure all profiles have the new weaponsSelected structure.

  if (typeof profile.weaponsSelected === "undefined") {
    profile.weaponsSelected = {
      melee: [],
      ranged: [],
    }
  }

  if (typeof profile.weaponsSelected.melee === "undefined") {
    profile.weaponsSelected.melee = []
  }

  if (typeof profile.weaponsSelected.ranged === "undefined") {
    profile.weaponsSelected.ranged = []
  }

  return profile
}
