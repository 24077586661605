export default function conditionAttackStepRoll(data) {
  const { condition, ellipsis } = data
  let description = ""

  let conditionAttackStep = null
  let conditionRollReq = null

  let rollState = ""
  let rollTarget = ""
  let rollValue = ""

  conditionAttackStep = condition.data.attackStep
  conditionRollReq = condition.data.rollReq
  rollState = condition.data.rollState
  rollTarget = condition.data.rollTarget
  rollValue = condition.data.rollValue ? String(condition.data.rollValue) : ellipsis

  if (conditionAttackStep && conditionRollReq === "successfulRoll") {
    let attackStep = "hit roll"
    if (conditionAttackStep === "woundRoll") {
      attackStep = "wound roll"
    }
    description = `Successful ${attackStep}`
  }

  if (conditionAttackStep && conditionRollReq === "specificRoll") {
    let rollCondition = ""
    let attackStep = "Hit roll"

    if (conditionAttackStep === "woundRoll") {
      attackStep = "Wound roll"
    }

    description = `${attackStep} of `

    description += rollValue
    if (rollTarget === "orGreater") {
      description += "+"
    }
    if (rollState === "unmodified") {
      rollCondition = " (unmodified)"
    }

    description += rollCondition
  }

  return description
}
