export default function migrateWeaponAbility(ability, profile) {
  // Update "modern" melta abilities from type "special" to type "conditionalEffect".
  if (ability.type !== "special" || typeof ability.option === "undefined") {
    return
  }
  if (typeof ability.option.value === "undefined") {
    return
  }

  if (
    ability.option.value === "meltaD6Plus2" ||
    ability.option.value === "meltaD6Plus4" ||
    ability.option.value === "meltaLegacy"
  ) {
    ability.attackType = "Ranged"
    ability.conditions = [
      {
        data: {
          range: { text: "Within half range", value: "withinHalfRange" },
        },
        required: true,
        type: "range",
      },
    ]
    ability.effect = {
      attackSequenceEnds: false,
      treatWoundAsUnmodified6: false,
      type: {
        operator: "replace",
        text: "Set damage",
        value: "setDamage",
        valueMax: null,
        valueMin: 1,
      },
    }
    ability.operator = "replace"
    ability.scope = ["weapon"]
    ability.type = "conditionalEffect"

    if (ability.option.value === "meltaD6Plus2" || ability.option.value === "meltaLegacy") {
      ability.description = "Within half range : set damage to D6+2"
      ability.effect.value = "D6+2"
    }
    if (ability.option.value === "meltaD6Plus4") {
      ability.description = "Within half range : set damage to D6+4"
      ability.effect.value = "D6+4"
    }
    delete ability.option
    ability.updated = Date.now()
  }

  profile.updateStatus.updated = true
}
