/*
 * These look like generic modifier updates that aren't exclusive to weapon abilities
 * but the timeline of how & when modifiers made it into local storage means that they
 * only really need to be applied to weapon abilities.
 * */

export default function updateWeaponAbility(ability, profile) {
  // Re-roll abilities that predate the use of the [ability].reRoll object.
  if (ability.type === "reRoll" && typeof ability.reRoll === "undefined") {
    if (ability.option.value === "reRollOnes") {
      ability.description = `${ability.application.text} : re-roll all results of 1`
      ability.reRoll = {
        limit: "none",
        resultToReRoll: "single",
        valueSingle: 1,
        valueRange: [],
      }
    }
    if (ability.option.value === "reRollOneFailed") {
      ability.reRoll = {
        limit: "single",
        resultToReRoll: "failed",
        valueSingle: null,
        valueRange: [],
      }
    }
    if (ability.option.value === "reRollFailed") {
      ability.reRoll = {
        limit: "none",
        resultToReRoll: "failed",
        valueSingle: null,
        valueRange: [],
      }
    }
    ability.option = null
    // Probably not necessary but seems tidy.
    ability.application.optionSets = ["modifyRoll"]
    ability.updated = Date.now()
    profile.updateStatus.updated = true
  }
  if (typeof ability.reRoll === "undefined") {
    ability.reRoll = {
      limit: "none",
      resultToReRoll: null,
      valueSingle: null,
      valueRange: [],
    }
    profile.updateStatus.updated = true
  }
}
