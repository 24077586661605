import migrateProfile from "~/utils/update/migrations/0.60.1/migrateProfile"

export default function migrate_0_60_1(profile) {
  // Profiles.
  migrateProfile(profile)

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
