export default function migrateModifier(ability) {
  if (ability.effect.type === "reRoll") {
    // Migrate all instances of "failed" to "allPossibleFailures".
    if (ability.effect.data.resultToReRoll === "failed") {
      ability.effect.data.resultToReRoll = "allPossibleFailures"
    }
  }

  return ability
}
