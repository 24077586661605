export default [
  {
    name: "Blast",
    descriptions: ["Blast"],
  },
  {
    name: "Conversion",
    descriptions: ["Hit roll of 4+ (unmodified) : critical hit (over half range)"],
  },
  {
    name: "Devastating wounds",
    descriptions: ["Critical wound : mortal wounds equal to weapon damage (attack sequence ends)"],
  },
  {
    name: "Heavy",
    descriptions: ["+1 to hit roll (if attacker remained stationary)"],
  },
  {
    name: "Ignores cover",
    descriptions: ["Ignore cover"],
  },
  {
    name: "Lance",
    descriptions: ["+1 to wound roll (if attacker charged)"],
  },
  {
    name: "Lethal hits",
    descriptions: ["Critical hit : always wound"],
  },
  {
    name: "Stealth",
    descriptions: ["-1 to hit roll (ranged only)", "-1 to hit roll (ranged only) (if defender)"],
  },
  {
    name: "Torrent",
    descriptions: ["Always hit"],
  },
  {
    name: "Twin-linked",
    descriptions: ["Re-roll all possible failed wound rolls"],
  },
]
