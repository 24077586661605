function sentenceCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function camelCaseToSentenceCase(string) {
  // Add spaces and make all lowercase.
  const lowerCaseWithSpaces = string.replace(/([A-Z])/g, " $1").toLowerCase()
  // Return string with first letter capitalised.
  return (
    lowerCaseWithSpaces.charAt(0).toUpperCase() + lowerCaseWithSpaces.slice(1)
  )
}

export default function migrateModifier(ability) {
  // Effects

  // Migrate `overrideReqs` effects from storing `outcome` & `type` as strings to using objects.
  if (ability.effect.type === "overrideReqs") {
    if (
      !Object.prototype.hasOwnProperty.call(
        ability.effect.data.outcome,
        "title"
      )
    ) {
      // ^ Checks if already migrated.
      const oldEffectData = { ...ability.effect.data }
      ability.effect.data.outcome = {
        title: sentenceCase(oldEffectData.outcome),
        key: oldEffectData.outcome,
      }
      ability.effect.data.type = {
        title: sentenceCase(oldEffectData.type),
        key: oldEffectData.type,
      }
    }
  }

  // Migrate `reRoll` effects from storing `limit` & `resultToReRoll` as strings to using objects.
  if (ability.effect.type === "reRoll") {
    if (
      !Object.prototype.hasOwnProperty.call(ability.effect.data.limit, "title")
    ) {
      // ^ Checks if already migrated.
      const oldEffectData = { ...ability.effect.data }
      ability.effect.data.limit = {
        title: sentenceCase(oldEffectData.limit),
        key: oldEffectData.limit,
      }
      if (oldEffectData.resultToReRoll === null) {
        // Edge case according to user submitted update bug report.
        // Just gets re-roll modifier back into a usable state.
        ability.effect.data.resultToReRoll = {
          title: "All possible failures",
          key: "allPossibleFailures",
        }
      } else {
        ability.effect.data.resultToReRoll = {
          title: sentenceCase(oldEffectData.resultToReRoll),
          key: oldEffectData.resultToReRoll,
        }
      }
      if (ability.effect.data.resultToReRoll.title === "AllPossibleFailures") {
        ability.effect.data.resultToReRoll.title = "All possible failures"
      }
    }
  }

  // Migrate any other effect select list object values from text/value to title/key.
  const effectDataKeys = Object.keys(ability.effect.data)
  effectDataKeys.forEach((key) => {
    if (typeof ability.effect.data[key] === "object") {
      if (ability.effect.data[key]?.hasOwnProperty("text")) {
        const oldObject = ability.effect.data[key]
        ability.effect.data[key] = {
          title: oldObject.text,
          key: oldObject.value,
        }
      }
    }
  })

  // Conditions

  ability.conditions.forEach((condition) => {
    // Migrate `attackStepRoll` conditions from storing
    // `attackStep`, `rollReq`, `rollState` & `rollTarget` as strings to using objects.
    if (condition.type === "attackStepRoll") {
      if (
        !Object.prototype.hasOwnProperty.call(
          condition.data.attackStep,
          "title"
        )
      ) {
        // ^ Checks if already migrated.
        const oldConditionData = { ...condition.data }
        condition.data.attackStep = {
          title: camelCaseToSentenceCase(oldConditionData.attackStep),
          key: oldConditionData.attackStep,
        }
        condition.data.rollReq = {
          title: camelCaseToSentenceCase(oldConditionData.rollReq),
          key: oldConditionData.rollReq,
        }
        condition.data.rollState = {
          title: camelCaseToSentenceCase(oldConditionData.rollState),
          key: oldConditionData.rollState,
        }
        condition.data.rollTarget = {
          title: camelCaseToSentenceCase(oldConditionData.rollTarget),
          key: oldConditionData.rollTarget,
        }
      }
    }

    // Migrate any other condition select list object values from text/value to title/key.
    const conditionDataKeys = Object.keys(condition.data)
    conditionDataKeys.forEach((key) => {
      if (typeof condition.data[key] === "object") {
        if (condition.data[key]?.hasOwnProperty("text")) {
          const oldObject = condition.data[key]
          condition.data[key] = {
            title: oldObject.text,
            key: oldObject.value,
          }
        }
      }
    })
  })

  return ability
}
