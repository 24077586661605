import modifierAliases from "~/utils/update/migrations/0.56.0/modifierAliases"

export default function migrateModifier(ability) {
  // Check for reserved aliases.
  const reservedAlias = modifierAliases(ability)

  // Add reserved alias if a match is found and the modifier had no aliases already.
  if (!ability.aliases.length && reservedAlias) {
    ability.aliases.push(reservedAlias)
    // Make alias active by default
    ability.aliasActive = true
  }

  return ability
}
