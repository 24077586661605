export default function migrate_0_42_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          // Update special ability "grav" to "gravD3".
          if (
            ability.type !== "special" ||
            typeof ability.option.value === "undefined"
          ) {
            return
          }
          if (ability.option.value === "grav") {
            ability.description = "Grav: D3"
            ability.option.description = "Grav: D3"
            ability.option.text = "Grav: D3"
            ability.option.value = "gravD3"
          }
          profile.updateStatus.updated = true
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
