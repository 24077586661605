import { defineStore } from "pinia"
import { ref } from "vue"

export const useUserConfigSimsStore = defineStore(
  "userConfigSims",
  () => {
    const autoRunSims = ref(true)
    const totalSims = ref(10000)

    return {
      autoRunSims,
      totalSims,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: 86400, // 1 day in seconds
        sameSite: true,
      }),
      pick: ["totalSims"],
    },
  }
)
