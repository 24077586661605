// NOTE: Migration 0.53.0 depends on this file.
// Do not update this file after 0.53.0 has shipped.

export const defaultModifierEffects = Object.freeze([
  {
    text: "Disable ability",
    type: "disableAbility",
    description: "Disable other abilities.",
    scope: ["profile", "weapon"],
    data: {
      option: null,
    },
  },
  {
    text: "Generate extras",
    type: "generateExtras",
    description: "Generate extra attacks, hits or wounds.",
    scope: ["global", "profile", "weapon"],
    data: {
      option: null,
      extrasValue: null,
    },
  },
  {
    text: "Ignore wounds",
    type: "ignoreWounds",
    description: `Ignore wounds ("feel no pain").`,
    scope: ["profile"],
    data: {
      ignoreValue: null,
    },
  },
  {
    text: "Invulnerable save",
    type: "invulnSave",
    description: "Provide an invulnerable save.",
    scope: ["profile"],
    data: {
      invulnValue: null,
    },
  },
  {
    text: "Modify (relative)",
    type: "modifyRelative",
    description: "Modify an attribute or result relative to its original value.",
    scope: ["global", "profile", "weapon"],
    data: {
      application: null,
      option: null,
    },
  },
  {
    text: "Modify (absolute)",
    type: "modifyAbsolute",
    description: "Set a new value for an attribute or result.",
    scope: ["profile", "weapon"],
    data: {
      application: null,
      operator: "replace",
      absoluteValue: null,
    },
  },
  {
    text: "Mortal wounds",
    type: "mortalWounds",
    description: "Generate mortal wounds.",
    scope: ["global", "profile", "weapon"],
    data: {
      attackSequenceEnds: false,
      cap: null,
      mortalWoundsValue: null,
    },
  },
  {
    text: "Override requirements",
    type: "overrideReqs",
    description: "Set new requirements for a successful outcome.",
    scope: ["global", "profile", "weapon"],
    data: {
      irrespective: false,
      outcome: null,
      treatWoundAsUnmodified6: false,
      type: null,
    },
  },
  {
    text: "Re-roll",
    type: "reRoll",
    description: "Re-roll specific results.",
    scope: ["global", "profile", "weapon"],
    data: {
      application: null,
      limit: "none",
      resultToReRoll: null,
      valueRange: [],
      valueSingle: null,
    },
  },
  {
    text: "Special",
    type: "special",
    description: "Niche special effects like Blast.",
    scope: ["weapon"],
    data: {
      option: null,
    },
  },
])
