export default function migrateProfile(profile) {
  // Split weaponsSelected into nested arrays.

  const weaponsSelectedTemp = {
    melee: [],
    ranged: [],
  }
  let weaponsSelectedUpdated = false

  if (Array.isArray(profile.weaponsSelected)) {
    profile.weaponsSelected.forEach((weaponSelectedId) => {
      // Determine if weapon is melee or ranged. Push ID to correct array.
      profile.weapons.forEach((weapon) => {
        if (weapon.id === weaponSelectedId) {
          if (weapon.type === "Melee") {
            weaponsSelectedTemp.melee.push(weaponSelectedId)
          } else if (weapon.type === "Ranged") {
            weaponsSelectedTemp.ranged.push(weaponSelectedId)
          }
        }
      })
    })
    weaponsSelectedUpdated = true
  }

  if (weaponsSelectedUpdated) {
    profile.weaponsSelected = weaponsSelectedTemp
  }

  return profile
}
