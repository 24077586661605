import migrateModifier from "~/utils/update/migrations/0.59.0/migrateModifier"
import migrateProfile from "~/utils/update/migrations/0.59.0/migrateProfile"

export default function migrate_0_59_0(profile) {
  // Profiles.
  migrateProfile(profile)

  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      migrateModifier(profileAbility)
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((weaponAbility) => {
          migrateModifier(weaponAbility)
        })
      }
    })
  }

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
