import migrateModifier from "~/utils/update/migrations/0.58.1/migrateModifier"

export default function migrate_0_58_1(profile) {
  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      migrateModifier(profileAbility)
    })
  }

  // Mark as updated.
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
