export default function effectOverrideReqs(data) {
  const { effect, ellipsis } = data
  let description = ""

  let overrideType = ""
  let postCondition = null

  if (effect.data.type?.key) {
    overrideType = "Only"
    if (effect.data.type?.key === "always") {
      overrideType = "Always"
    }
    description = `${overrideType} `
  }

  if (effect.data.type?.key && !effect.data.outcome?.key) {
    description += ellipsis
  }

  if (effect.data.type?.key && effect.data.outcome?.title) {
    description += effect.data.outcome.title

    if (effect.data.crit) {
      description = `critical ${effect.data.outcome.title}`
    }

    if (effect.data.irrespective) {
      postCondition = "(irrespective of attack modifiers)"
    }
  }

  return {
    description,
    postCondition,
  }
}
