import reservedModifierAliases from "~/utils/modifierAliases/reservedModifierAliases"

export default function modifierAliases(modifier) {
  // Returns reserved alias object if modifier description match is found.
  // Otherwise, returns null.

  // Check reservedModifierAliases array for a match.
  for (const reservedAlias of reservedModifierAliases) {
    const description = modifier?.description ? modifier.description : ""
    const alias = reservedAlias(description)
    if (alias) {
      // Simplify any "(melee only)" or "(ranged only)" suffix to be just "(melee)" or "(ranged)".
      const aliasProcessed = alias.replace(/(melee|ranged) only/, "$1")
      return {
        name: aliasProcessed,
        type: "reserved",
      }
    }
  }

  return null
}
