import { regexDiceNotation } from "~/utils/regex"

export default [
  // Anti
  (description) => {
    const regex = new RegExp(
      `^Wound roll of ([2-5])\\+ \\(unmodified\\) : critical wound( \\((melee|ranged) only\\))? \\(([A-Z]+) only\\)( \\(if attacker\\))?$`,
      "i"
    )
    const match = description.match(regex)
    if (match) {
      // Possible melee|ranged only suffix.
      const suffix = match[2] ?? ""
      return `Anti-${match[4]} ${match[1]}+${suffix}`
    }
    return null
  },
  // Blast
  (description) => {
    const regex = /^Blast$/
    return description.match(regex) ? "Blast" : null
  },
  // Conversion
  (description) => {
    const regex =
      /^Hit roll of 4\+ \(unmodified\) : critical hit \(over half range\)$/
    return description.match(regex) ? "Conversion" : null
  },
  // Devastating wounds
  (description) => {
    const regex = new RegExp(
      `^Critical wound : mortal wounds equal to weapon damage \\(attack sequence ends\\) \\(no spill over\\)( \\((melee|ranged) only\\))?( \\(if attacker\\))?$`,
      "i"
    )
    const match = description.match(regex)
    if (match) {
      // Possible melee|ranged only suffix.
      const suffix = match[1] ?? ""
      return `Devastating wounds${suffix}`
    }
    return null
  },
  // Extra attacks
  (description) => {
    const regex = /^Extra attacks$/
    return description.match(regex) ? "Extra attacks" : null
  },
  // Heavy
  (description) => {
    const regex =
      /^\+1 to hit roll( \(if attacker\))? \(if attacker remained stationary\)$/
    return description.match(regex) ? "Heavy" : null
  },
  // Ignores cover
  (description) => {
    const regex = /^Ignore cover( \(if attacker\))?$/
    return description.match(regex) ? "Ignores cover" : null
  },
  // Lance
  (description) => {
    const regex = /^\+1 to wound roll \(if attacker charged\)$/
    return description.match(regex) ? "Lance" : null
  },
  // Lethal hits
  (description) => {
    const regex = new RegExp(
      `^Critical hit : always wound( \\((melee|ranged) only\\))?( \\(if attacker\\))?$`,
      "i"
    )
    const match = description.match(regex)
    if (match) {
      // Possible melee|ranged only suffix.
      const suffix = match[1] ?? ""
      return `Lethal hits${suffix}`
    }
    return null
  },
  // Melta
  (description) => {
    const regex = new RegExp(
      `^\\+(${regexDiceNotation}) to damage \\(within half range\\)$`,
      "i"
    )
    const match = description.match(regex)
    return match ? `Melta ${match[1]}` : null
  },
  // Psychic
  (description) => {
    const regex = /^Psychic( \(if attacker\))?$/
    return description.match(regex) ? "Psychic" : null
  },
  // Rapid fire
  (description) => {
    const regex = new RegExp(
      `^Increase attacks by (${regexDiceNotation}) \\(within half range\\)$`,
      "i"
    )
    const match = description.match(regex)
    return match ? `Rapid fire ${match[1]}` : null
  },
  // Stealth
  (description) => {
    const regex = /^-1 to hit roll \(ranged only\)( \(if defender\))?$/
    return description.match(regex) ? "Stealth" : null
  },
  // Sustained hits
  (description) => {
    // Technically this regex pattern will also match nonsense like
    // "Critical hit : 1D3 extra hits" (note the "1D3")
    // but we don't need to worry as the modifier description
    // shouldn't output that kind of nonsense in the first place.
    const regex = new RegExp(
      `^Critical hit : (${regexDiceNotation}) extra hit(s?)( \\((melee|ranged) only\\))?( \\(if attacker\\))?$`,
      "i"
    )
    const match = description.match(regex)
    if (match) {
      // Possible melee|ranged only suffix.
      const suffix = match[6] ?? ""
      return `Sustained hits ${match[1]}${suffix}`
    }
    return null
  },
  // Torrent
  (description) => {
    const regex = /^Always hit$/
    return description.match(regex) ? "Torrent" : null
  },
  // Twin-linked
  (description) => {
    const regex = /^Re-roll all possible failed wound rolls$/
    return description.match(regex) ? "Twin-linked" : null
  },
]
