import migrateWeaponAbility from "~/utils/update/migrations/0.51.0/migrateWeaponAbility"

export default function migrate_0_51_0(profile) {
  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((ability) => {
          migrateWeaponAbility(ability, profile)
        })
      }
    })
  }

  // Return migrated profile.
  return profile
}
