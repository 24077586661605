export default function migrateModifier(ability) {
  // Update legacy "Dev wounds" abilities to be new-style "Dev Wounds" abilities.

  let isDevWounds = false

  ability.aliases.forEach((alias) => {
    if (alias.name === "Devastating wounds") {
      isDevWounds = true
    }
  })

  if (isDevWounds) {
    ability.conditions = [
      {
        text: "Critical hit/wound",
        type: "crit",
        data: {
          crit: {
            text: "Critical wound",
            value: "woundRoll",
          },
        },
        description: 'Require a hit or wound to be "critical".',
        preselected: false,
        scope: ["global", "profile", "weapon"],
      },
    ]
    ability.description = "Critical wound : disable save (inc. invuln)"
    ability.effect = {
      text: "Disable mechanic",
      type: "disableAbility",
      data: {
        option: {
          text: "Disable save (inc. invuln)",
          value: "disableSave",
        },
      },
    }
  }

  return ability
}
