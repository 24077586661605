export default function migrate_0_52_0(profile) {
  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((ability) => {
      // Mark ability for removal if it's a single-use re-roll.
      // These should only be used in the "weapon" scope from now on.
      if (ability.reRoll?.limit === "single") {
        ability.remove = true
        profile.updateStatus.updated = true
      }
    })

    // Set new abilities array by filtering out abilities that were marked for removal.
    // E.g. ability.remove === true
    // Note: We don't need to worry about updating the abilitiesSelected array here,
    // that's handled later in the profile update process.
    profile.abilities = profile.abilities.filter((ability) => {
      return !(typeof ability.remove !== "undefined" && ability.remove)
    })
  }

  // Return migrated profile.
  return profile
}
