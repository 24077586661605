import { regexDiceNotation } from "~/utils/update/migrations/0.54.0/regex"

export default function RapidFireN(description) {
  const regex = new RegExp(
    `^Increase attacks by (${regexDiceNotation}) \\(within half range\\)$`,
    "i"
  )
  const result = description.match(regex)
  if (result) {
    return {
      name: `Rapid fire ${result[1]}`,
      type: "reserved",
    }
  }
  return null
}
