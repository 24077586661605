export default function migrateProfile(profile) {
  // Generate computed keywords if needed.

  if (typeof profile.computed.keywords === "undefined") {
    profile.computed.keywords = []
  }

  if (!profile.computed.keywords.length) {
    profile.computed.keywords = []
    profile.units.forEach((unit) => {
      unit.modelTypes.forEach((modelType) => {
        if (typeof modelType.keywords === "undefined") {
          modelType.keywords = []
        }
        if (modelType?.keywords.length) {
          // Sort this modelType's keywords while we're here.
          modelType.keywords.sort()
          modelType.keywords.forEach((keyword) => {
            if (!profile.computed.keywords.includes(keyword)) {
              profile.computed.keywords.push(keyword)
            }
          })
        }
      })
    })
    profile.computed.keywords.sort()
  }

  return profile
}
