import { cloneDeep } from "lodash-es"
import { timestamp } from "~/utils/utils"

export default function cleanProfile(incomingProfile, options = {}) {
  // console.log("incomingProfile", incomingProfile)

  const {
    // Whether to directly mutate the incoming modifier or not.
    mutate = false,
  } = options

  const profile = mutate ? incomingProfile : cloneDeep(incomingProfile)

  // Re-assign weapon IDs and update weaponsSelected to match.
  // This cleans up weapon IDs to start from 1 and be consecutive.

  profile.weaponIdNext = 1

  const weaponsSelectedNew = {
    melee: [],
    ranged: [],
  }

  profile.units.forEach((unit) => {
    for (let modelType of unit.modelTypes) {
      if (typeof modelType.weapons === "undefined") {
        continue
      }
      // Sort weapons by ID ascending.
      // They probably already are sorted this way, this is belt & braces.
      modelType.weapons.sort((a, b) => {
        return a.id - b.id
      })
      modelType.weapons.forEach((weapon) => {
        const oldId = weapon.id
        weapon.id = profile.weaponIdNext
        if (profile?.weaponsSelected?.melee.includes(oldId)) {
          if (weapon.type === "Melee") {
            weaponsSelectedNew.melee.push(weapon.id)
          }
        }
        if (profile?.weaponsSelected?.ranged.includes(oldId)) {
          if (weapon.type === "Ranged") {
            weaponsSelectedNew.ranged.push(weapon.id)
          }
        }
        profile.weaponIdNext++
      })
    }
  })

  profile.weaponsSelected = weaponsSelectedNew

  profile.updated = timestamp()

  // console.log("profile", profile)

  return profile
}
