export default function conditionDefenderCharacteristic(data) {
  const { condition, ellipsis } = data
  let description = ""

  let conditionCharState = ""
  const conditionComparison = condition.data.comparison.title

  const conditionChar = condition.data.defenderChar.title
    ? condition.data.defenderChar.title
    : ellipsis

  const conditionCharValue = condition.data.defenderCharValue
    ? String(condition.data.defenderCharValue)
    : ellipsis

  if (condition.data.defenderCharState.key === "preRoll") {
    conditionCharState = " (pre-roll)"
  }

  if (condition.data.defenderCharState.key === "unmodified") {
    conditionCharState = " (unmodified)"
  }

  if (conditionComparison) {
    description = `${conditionChar}${conditionCharState} is ${conditionComparison} ${conditionCharValue}`
  }

  return description
}
