<script setup>
const runtimeConfig = useRuntimeConfig()
</script>

<template>
  <div class="message">
    Version {{ runtimeConfig.public.clientVersion }} released! See the
    <a href="/changelog" class="link">changelog</a> for details.
  </div>
</template>

<style scoped>
.message {
  font-family: Roboto, sans-serif;
}
.link {
  color: white;
  text-decoration: underline;
}
</style>
