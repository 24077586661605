import { cloneDeep } from "lodash-es"
import { applyUpdate } from "~/utils/update/utils"
import migrateModifier_0_60_0 from "~/utils/update/migrations/0.60.0/migrateModifier"
import migrateModifier_0_61_0 from "~/utils/update/migrations/0.61.0/migrateModifier"
import migrateModifier_0_63_0 from "~/utils/update/migrations/0.63.0/migrateModifier"
import migrateModifier_0_64_0 from "~/utils/update/migrations/0.64.0/migrateModifier"
import migrateModifier_0_64_1 from "~/utils/update/migrations/0.64.1/migrateModifier"
import migrateModifier_0_65_0 from "~/utils/update/migrations/0.65.0/migrateModifier"
import migrateModifier_0_66_0 from "~/utils/update/migrations/0.66.0/migrateModifier"

export default function updateModifier(inputModifier, updateContext) {
  // Returns a processed clone of the input modifier
  // including an extra `updateStatus` property to track the modifier's update status.

  // console.log("updateModifier()", updateContext)

  let modifier = cloneDeep(inputModifier)

  // Run migrations.

  if (applyUpdate("0.60.0", updateContext)) {
    modifier = migrateModifier_0_60_0(modifier)
  }

  if (applyUpdate("0.61.0", updateContext)) {
    modifier = migrateModifier_0_61_0(modifier)
  }

  if (applyUpdate("0.63.0", updateContext)) {
    modifier = migrateModifier_0_63_0(modifier)
  }

  if (applyUpdate("0.64.0", updateContext)) {
    modifier = migrateModifier_0_64_0(modifier)
  }

  if (applyUpdate("0.64.1", updateContext)) {
    modifier = migrateModifier_0_64_1(modifier)
  }

  if (applyUpdate("0.64.2", updateContext)) {
    // Re-apply 0.64.1 migration.
    modifier = migrateModifier_0_64_1(modifier)
  }

  if (applyUpdate("0.65.0", updateContext)) {
    modifier = migrateModifier_0_65_0(modifier)
  }

  if (applyUpdate("0.66.0", updateContext)) {
    modifier = migrateModifier_0_66_0(modifier)
  }

  return modifier
}
