export default function migrateModifier(ability) {
  // Ensure "Dev wounds" profile abilities have the "profileRole" condition.

  let isDevWounds = false

  ability.aliases.forEach((alias) => {
    if (alias.name === "Devastating wounds") {
      isDevWounds = true
    }
  })

  if (isDevWounds) {
    ability.conditions = [
      {
        text: "Profile role",
        type: "profileRole",
        data: {
          profileRole: "Attacker",
        },
        description: "Require the current profile to be set as the attacker or defender.",
        preselected: true,
      },
      {
        text: "Critical hit/wound",
        type: "crit",
        data: {
          crit: {
            text: "Critical wound",
            value: "woundRoll",
          },
        },
        description: 'Require a hit or wound to be "critical".',
        preselected: false,
      },
    ]
  }

  return ability
}
