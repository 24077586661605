export default function migrate_0_47_6(profile) {
  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((ability) => {
      if (
        ability.description ===
        "Strength is less than 8 : reduce damage by 1 (if defender)"
      ) {
        for (const condition of ability.conditions) {
          if (
            typeof condition.data.attackCharState !== "undefined" &&
            condition.data.attackCharState === "modified"
          ) {
            condition.data.attackCharState = {
              text: "Modified",
              value: "modified",
            }
          }
        }
      }
      profile.updateStatus.updated = true
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon) => {
      // Ensure weapon has an ID.
      if (typeof weapon.id === "undefined") {
        weapon.id = Number(profile.weaponIdNext)
        profile.weaponIdNext++
        profile.updateStatus.updated = true
      }
    })
  }

  // Return migrated profile.
  return profile
}
