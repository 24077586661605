export default function migrateWeapon(profile, weapon) {
  // Ensure all weapons have a countPerUnit property.
  // Initialise with value of 1 if not present or falsy.
  if (typeof weapon.countPerUnit === "undefined" || !weapon.countPerUnit) {
    weapon.countPerUnit = 1
  }

  // Normalise "no value" / "special" weapon.strength to always use "*".
  if (weapon.strength === "*" || weapon.strength === "-") {
    weapon.strength = "*"
  }

  // Resolve relative strength and write to weapon.strength.

  if (typeof profile.strength !== "undefined") {
    if (weapon.strength === "User") {
      weapon.strength = Number(profile.strength)
    }

    // Assuming weapon strength is an operator followed by a number, attempt to extract the operator & number.
    const operatorString = String(weapon.strength).slice(0, 1)
    const valueNum = Number(String(weapon.strength).slice(1))

    if (operatorString === "+") {
      weapon.strength = Number(profile.strength) + valueNum
    }

    if (operatorString === "x" || operatorString === "×") {
      weapon.strength = Number(profile.strength) * valueNum
    }
  }

  // Migrate profile bs / ws to weapon bs / ws.
  if (typeof weapon.bs === "undefined") {
    weapon.bs = null
  }
  if (typeof weapon.ws === "undefined") {
    weapon.ws = null
  }
  if (typeof profile.bs !== "undefined" && weapon.type === "Ranged") {
    weapon.bs = profile.bs
  }
  if (typeof profile.ws !== "undefined" && weapon.type === "Melee") {
    weapon.ws = profile.ws
  }

  // Migrate all Rapid Fire & Dakka weapons to relevant abilities.
  if (["Dakka", "Rapid Fire"].includes(weapon.subType)) {
    let rapidFireNumber = weapon.attacks
    let dakkaValues = []
    if (weapon.subType === "Dakka") {
      dakkaValues = weapon.attacks.split("/")
      rapidFireNumber = dakkaValues[0] - dakkaValues[1]
    }

    if (typeof weapon.abilities === "undefined") {
      weapon.abilities = []
    }

    if (typeof weapon.abilityIdNext === "undefined" || weapon.abilityIdNext === null) {
      weapon.abilityIdNext = 1
    }

    weapon.abilities.push({
      aliases: [],
      aliasActive: true,
      conditions: [
        {
          text: "Range",
          type: "range",
          data: { range: { text: "Within half range", value: "withinHalfRange" } },
          description: "Check attack range.",
          preselected: false,
        },
      ],
      description: `Increase attacks by ${rapidFireNumber} (within half range)`,
      effect: {
        text: "Increase weapon attacks",
        type: "increaseWeaponAttacks",
        data: {
          increaseWeaponAttacksValue: Number(rapidFireNumber),
        },
      },
      id: weapon.abilityIdNext,
      scope: ["weapon"],
      updated: Date.now(),
    })

    weapon.abilityIdNext++

    if (weapon.subType === "Dakka") {
      // Set Dakka weapon attacks to the lowest of the 2 dakkaValues.
      weapon.attacks = Number(dakkaValues[1])
    }
  }
}
