export default function migrate_0_47_0(profile) {
  // Ensure all profiles have a profileTemplate property. Default to "standard".
  if (typeof profile.profileTemplate === "undefined") {
    profile.profileTemplate = "standard"
  }
  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
