export default function effectModifyAbsolute(data) {
  const { effect, ellipsis } = data
  let description = ""

  if (effect.data.application) {
    description = `Set ${effect.data.application.title} to `
    // null is important here as value could legitimately be 0.
    if (effect.data.absoluteValue !== null) {
      description += `${String(effect.data.absoluteValue)}`
    } else {
      description += ellipsis
    }
  }

  return {
    description,
  }
}
