export default function migrateWeaponAbility(ability, profile) {
  // Migrate legacy grav* abilities to use
  // conditional effects that observe the defender's "save" characteristic.

  if (ability.type !== "special" || typeof ability.option === "undefined") {
    return
  }
  if (typeof ability.option.value === "undefined") {
    return
  }

  if (ability.option.value === "grav2" || ability.option.value === "grav3" || ability.option.value === "gravD3") {
    ability.conditions = [
      {
        type: "defenderCharacteristic",
        data: {
          defenderChar: { text: "Save", value: "save" },
          defenderCharValue: "4",
          comparison: { text: "Less than", value: "lessThan" },
          defenderCharState: { text: "Unmodified", value: "unmodified" },
        },
        required: true,
      },
    ]
    ability.effect = {
      attackSequenceEnds: false,
      treatWoundAsUnmodified6: false,
      type: {
        text: "Set damage",
        value: "setDamage",
        valueMin: 1,
        valueMax: null,
        operator: "replace",
      },
      // Default to be updated further down.
      value: "D3",
    }
    ability.operator = "replace"
    ability.scope = ["weapon"]
    ability.type = "conditionalEffect"

    if (ability.option.value === "grav2") {
      ability.description += "Save (unmodified) is less than 4 : set damage to 2"
      ability.effect.value = "2"
    }
    if (ability.option.value === "grav3") {
      ability.description += "Save (unmodified) is less than 4 : set damage to 3"
      ability.effect.value = "3"
    }
    if (ability.option.value === "gravD3") {
      ability.description += "Save (unmodified) is less than 4 : set damage to D3"
      ability.effect.value = "D3"
    }
    delete ability.option
    ability.updated = Date.now()
  }

  profile.updateStatus.updated = true
}
