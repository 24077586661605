export default function migrateModifier(modifier) {
  // Add sharedWithAllUnits property and set to false if it doesn't exist.
  modifier.sharedWithAllUnits = modifier?.sharedWithAllUnits || false

  // Any modifiers found using the "attackDamageRollsOver" effect should be
  // updated to instead just apply devastating wounds.
  if (modifier?.effect?.data?.option?.key === "excessDamageNotLost") {
    modifier.aliases = [
      {
        name: "Devastating wounds",
        type: "reserved",
      },
    ]
    modifier.aliasActive = true
    modifier.conditions = [
      {
        text: "Critical hit/wound",
        type: "crit",
        data: {
          crit: {
            title: "Critical wound",
            key: "woundRoll",
          },
        },
        description: 'Require a hit or wound to be "critical".',
        preselected: false,
        scope: ["global", "profile", "weapon"],
      },
    ]
    modifier.description = "Critical wound : disable save (inc. invuln)"
    modifier.effect = {
      text: "Disable mechanic",
      type: "disableAbility",
      data: {
        option: {
          title: "Disable save (inc. invuln)",
          key: "disableSave",
        },
      },
    }
  }

  return modifier
}
