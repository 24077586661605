export default function migrateProfile(profile) {
  // Update all profiles to new multi-model-type & multi-unit shape.

  // If profile is already migrated, return it.
  if (profile?.units?.length) {
    return profile
  }

  // Migrate profile.

  // Separate properties that need to move out of model type.
  const {
    abilities,
    abilityIdNext,
    abilitiesSelected,
    id,
    ignore,
    invuln,
    modelCount,
    name,
    points,
    save,
    toughness,
    updateStatus,
    weaponIdNext,
    weaponsSelected,
    wounds,
    ...modelType
  } = profile

  const roles = profile.roles || []

  // New profile shape.
  return {
    abilityIdNext,
    abilitiesSelected,
    computed: {
      keywords: profile.keywords,
      leaders: [],
      modelCount: profile.modelCount,
      points,
      roles,
      toughness: profile.toughness,
      wounds: profile.wounds,
    },
    id,
    modelTypeIdNext: 2,
    name,
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    unitIdNext: 2,
    units: [
      {
        abilities: abilities || [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            ...modelType,
            id: 1,
            ignore: ignore || null,
            invuln: invuln || null,
            modelCount: modelCount || null,
            name,
            save: save || null,
            toughness: toughness || null,
            wounds: wounds || null,
          },
        ],
        name,
        points,
      },
    ],
    updateStatus,
    weaponIdNext,
    weaponsSelected: weaponsSelected || { melee: [], ranged: [] },
  }
}
