import cleanModifier from "~/utils/update/migrations/0.39.0/cleanModifier"
import cleanProfile from "~/utils/update/migrations/0.39.0/cleanProfile"
import cleanWeapon from "~/utils/update/migrations/0.39.0/cleanWeapon"

export default function migrate_0_39_0(profile) {
  // Update profile object to use new "concise" structure.
  profile = cleanProfile(profile)

  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      // Update profile ability object to use new "concise" structure.
      cleanModifier(profileAbility, { mutate: true })
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Update weapon object to use new "concise" structure.
      cleanWeapon(weapon, { mutate: true })

      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((weaponAbility) => {
          // Update weapon ability object to use new "concise" structure.
          cleanModifier(weaponAbility, { mutate: true })
        })
      }
    })
  }

  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
