function updateKeywords(ability) {
  // Migrate ability keywords to use new conditions property.
  if (typeof ability.keywords === "undefined") {
    return
  }

  const keywordsExcluded = typeof ability.keywords.exclude !== "undefined" ? ability.keywords.exclude : []
  const keywordsOnly = typeof ability.keywords.include !== "undefined" ? ability.keywords.include : []

  if (!keywordsExcluded.length && !keywordsOnly.length) {
    return
  }

  if (typeof ability.conditions === "undefined") {
    ability.conditions = []
  }

  if (keywordsExcluded.length) {
    ability.conditions.push({
      type: "keywordsExcluded",
      data: {
        keywords: keywordsExcluded,
      },
    })
  }

  if (keywordsOnly.length) {
    ability.conditions.push({
      type: "keywordsOnly",
      data: {
        keywords: keywordsOnly,
      },
    })
  }

  delete ability.keywords
}

function updateRollForEffect(ability) {
  if (ability.type !== "rollForEffect" || typeof ability.condition === "undefined") {
    return
  }
  ability.type = "conditionalEffect"
  if (ability.condition) {
    if (typeof ability.conditions === "undefined") {
      ability.conditions = []
    }
    ability.conditions.push({
      type: "rollAttackStep",
      required: true,
      data: {
        attackStep: ability.condition.event,
        rollReq: ability.condition.eventType,
        rollState: ability.condition.rollState,
        rollTarget: ability.condition.rollThreshold,
        rollValue: ability.condition.rollValue,
      },
    })
  }
}

export { updateKeywords, updateRollForEffect }
