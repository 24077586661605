function updateApplicationTypes(ability) {
  if (
    typeof ability.application !== "undefined" &&
    typeof ability.application.types !== "undefined"
  ) {
    delete ability.application.types
  }
}
export default function migrate_0_44_0(profile) {
  // Sort profile roles.
  profile.roles.sort()

  // Profile abilities.
  if (profile.abilities?.length) {
    profile.abilities.forEach((profileAbility) => {
      updateApplicationTypes(profileAbility)
    })
  }

  // Profile weapons.
  if (profile.weapons?.length) {
    profile.weapons.forEach((weapon, weaponIndex) => {
      // Weapon abilities.
      if (profile.weapons[weaponIndex].abilities?.length) {
        profile.weapons[weaponIndex].abilities.forEach((weaponAbility) => {
          updateApplicationTypes(weaponAbility)
        })
      }
    })
  }

  profile.updateStatus.updated = true

  // Return migrated profile.
  return profile
}
