export default defineNuxtRouteMiddleware((to) => {
  // Guard auth routes if env is not dev.
  if (!import.meta.dev) {
    const authRoutes = [
      "/account",
      "/confirm",
      "/login",
      "/reset-password",
      "/sign-up",
    ]
    if (authRoutes.includes(to.path)) {
      return abortNavigation()
    }
  }
})
