export default function migrateProfile(profile) {
  // If a profile only has 1 weapon of a certain type,
  // make sure that it's selected.

  if (profile?.weapons?.length) {
    if (typeof profile.weaponsSelected === "undefined") {
      profile.weaponsSelected = {
        ranged: [],
        melee: [],
      }
    }

    if (!profile.weaponsSelected.ranged.length) {
      const rangedWeapons = profile.weapons.filter(
        (weapon) => weapon.type === "Ranged"
      )
      if (rangedWeapons?.length === 1) {
        profile.weaponsSelected.ranged.push(rangedWeapons[0].id)
      }
    }

    if (!profile.weaponsSelected.melee.length) {
      const meleeWeapons = profile.weapons.filter(
        (weapon) => weapon.type === "Melee"
      )
      if (meleeWeapons?.length === 1) {
        profile.weaponsSelected.melee.push(meleeWeapons[0].id)
      }
    }
  }

  return profile
}
