// NOTE: Migration 0.53.0 depends on this file.
// Do not update this file after 0.53.0 has shipped.

export const defaultModifierConditions = Object.freeze([
  {
    text: "Attack characteristic",
    type: "attackCharacteristic",
    data: {
      attackChar: {
        text: null,
        value: null,
      },
      attackCharValue: null,
      attackCharState: {
        text: "Modified",
        value: "modified",
      },
      comparison: {
        text: "Equal to",
        value: "equalTo",
      },
    },
    description: "Evaluate an attack characteristic.",
    preselected: false,
  },
  {
    text: "Attack step roll",
    type: "attackStepRoll",
    data: {
      attackStep: null,
      rollReq: "specificRoll",
      rollState: "unmodified",
      rollTarget: "orGreater",
      rollValue: null,
    },
    description: "Require a specific roll value in the attack sequence.",
    preselected: false,
  },
  {
    text: "Attack type",
    type: "attackType",
    data: {
      attackType: null,
    },
    description: "Restrict to just melee attacks or ranged attacks.",
    preselected: false,
  },
  {
    text: "Defender characteristic",
    type: "defenderCharacteristic",
    data: {
      defenderChar: {
        text: null,
        value: null,
      },
      defenderCharValue: null,
      defenderCharState: {
        text: "Unmodified",
        value: "unmodified",
      },
      comparison: {
        text: "Equal to",
        value: "equalTo",
      },
    },
    description: "Evaluate a defender characteristic.",
    preselected: false,
  },
  {
    text: "Keywords (excludes)",
    type: "keywordsExcludes",
    data: {
      keywords: [],
    },
    description: "Exclude one or more keywords.",
    preselected: false,
  },
  {
    text: "Keywords (only)",
    type: "keywordsOnly",
    data: {
      keywords: [],
    },
    description: "Require one or more keywords.",
    preselected: false,
  },
  {
    text: "Profile role",
    type: "profileRole",
    data: {
      profileRole: null,
    },
    description: "Require the current profile to be set as the attacker or defender.",
    preselected: false,
  },
  {
    text: "Range",
    type: "range",
    data: {
      range: {
        text: "Within half range",
        value: "withinHalfRange",
      },
    },
    description: "Check attack range.",
    preselected: false,
  },
  {
    text: "Wound type",
    type: "woundType",
    data: {
      woundType: {
        text: "Mortal wounds",
        value: "mortalWounds",
      },
    },
    description: "Only apply to mortal wounds.",
    preselected: false,
  },
])
