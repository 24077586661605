export default function conditionAttackCharacteristic(data) {
  const { condition, ellipsis } = data
  let description = ""

  let conditionCharState = ""
  const conditionComparison = condition.data.comparison.text

  const conditionChar = condition.data.attackChar.text ? condition.data.attackChar.text : ellipsis

  const conditionCharValue = condition.data.attackCharValue ? String(condition.data.attackCharValue) : ellipsis

  if (condition.data.attackCharState.value === "preRoll") {
    conditionCharState = " (pre-roll)"
  }

  if (condition.data.attackCharState.value === "unmodified") {
    conditionCharState = " (unmodified)"
  }

  if (conditionComparison) {
    description = `${conditionChar}${conditionCharState} is ${conditionComparison} ${conditionCharValue}`
  }

  return description
}
