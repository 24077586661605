export default [
  {
    stringAbility: "ANTI AIR",
    objectAbilities: [
      {
        description: "Hit roll : +1 (AIRCRAFT only)",
        aliases: [],
        id: null,
        updated: 1627164744101,
        type: "modify",
        operator: "add",
        application: {
          text: "Hit roll",
          value: "hitRoll",
          types: ["modify", "reRoll"],
          optionSets: ["modifyRoll", "reRoll"],
        },
        option: { text: "+1", value: 1, operator: "add" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        keywords: { exclude: [], include: ["AIRCRAFT"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "ANTI AIR:LEGACY",
    objectAbilities: [
      {
        description: "Hit roll : +1 (FLY only)",
        aliases: [],
        id: null,
        updated: 1627164898908,
        type: "modify",
        operator: "add",
        application: {
          text: "Hit roll",
          value: "hitRoll",
          types: ["modify", "reRoll"],
          optionSets: ["modifyRoll", "reRoll"],
        },
        option: { text: "+1", value: 1, operator: "add" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        keywords: { exclude: [], include: ["FLY"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
      {
        description: "Hit roll : -1 (excludes FLY)",
        aliases: [],
        id: null,
        updated: 1627164944181,
        type: "modify",
        operator: "subtract",
        application: {
          text: "Hit roll",
          value: "hitRoll",
          types: ["modify", "reRoll"],
          optionSets: ["modifyRoll", "reRoll"],
        },
        option: { text: "-1", value: -1, operator: "subtract" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        keywords: { exclude: ["FLY"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "ARC:D3",
    objectAbilities: [
      {
        description: "Always wound on a 4+ (unmodified) (VEHICLE only)",
        aliases: [],
        id: 1,
        updated: 1633946626517,
        type: "overrideReqs",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "4",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
      {
        description: "Set damage to 2 (VEHICLE only)",
        aliases: [],
        id: 2,
        updated: 1633946757249,
        type: "setEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "unconditional",
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll", "unconditional"],
            valueMin: 1,
            operator: "replace",
          },
          value: "2",
          cap: null,
        },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "ARC:D6",
    objectAbilities: [
      {
        description: "Always wound on a 4+ (unmodified) (VEHICLE only)",
        aliases: [],
        id: 1,
        updated: 1633946626517,
        type: "overrideReqs",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "4",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
      {
        description: "Set damage to 3 (VEHICLE only)",
        aliases: [],
        id: 2,
        updated: 1633946850235,
        type: "setEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "unconditional",
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll", "unconditional"],
            valueMin: 1,
            operator: "replace",
          },
          value: "3",
          cap: null,
        },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "AUTO HIT",
    objectAbilities: [
      {
        description: "Always hit",
        aliases: [],
        id: null,
        updated: 1627413842134,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "always", outcome: "hit" },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "BLAST",
    objectAbilities: [
      {
        description: "Blast",
        aliases: [],
        id: null,
        updated: 1627470957956,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Blast", value: "blast" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "DENY INVULN",
    objectAbilities: [
      {
        description: "Deny invuln",
        aliases: [],
        id: null,
        updated: 1627470979435,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Deny invuln", value: "denyInvuln" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "GRAV",
    objectAbilities: [
      {
        description: "Grav",
        aliases: [],
        id: null,
        updated: 1627471002669,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Grav", value: "grav" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "HIT:6+=+2HITS",
    objectAbilities: [
      {
        description: "Hit roll of 6+ : 2 extra hits",
        aliases: [],
        id: null,
        updated: 1627165067141,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Extra hit(s)",
            value: "extraHits",
            conditionEvents: ["hitRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "HIT:6=+2HITS",
    objectAbilities: [
      {
        description: "Hit roll of 6 (unmodified) : 2 extra hits",
        aliases: [],
        id: null,
        updated: 1627232243372,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Extra hit(s)",
            value: "extraHits",
            conditionEvents: ["hitRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "HIT:6=AUTO WOUND",
    objectAbilities: [
      {
        description: "Hit roll of 6 (unmodified) : 1 auto-wound",
        aliases: [],
        id: null,
        updated: 1627165190297,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Auto-wound",
            value: "autoWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "HIT:6=AUTO WOUND:!VEHICLE",
    objectAbilities: [
      {
        description: "Hit roll of 6 (unmodified) : 1 auto-wound (excludes VEHICLE)",
        aliases: [],
        id: null,
        updated: 1627165225920,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Auto-wound",
            value: "autoWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        keywords: { exclude: ["VEHICLE"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "MELTA:D6+2",
    objectAbilities: [
      {
        description: "Melta: D6+2",
        aliases: [],
        id: null,
        updated: 1627470610994,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Melta: D6+2", value: "meltaD6Plus2" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "MELTA:D6+4",
    objectAbilities: [
      {
        description: "Melta: D6+4",
        aliases: [],
        id: null,
        updated: 1627470890876,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Melta: D6+4", value: "meltaD6Plus4" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "MELTA:LEGACY",
    objectAbilities: [
      {
        description: "Melta: legacy",
        aliases: [],
        id: null,
        updated: 1627470933912,
        type: "special",
        operator: null,
        application: null,
        option: { text: "Melta: legacy", value: "meltaLegacy" },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "PLAGUE WEAPON",
    objectAbilities: [
      {
        description: "Wound roll : re-roll ones",
        aliases: [],
        id: null,
        updated: 1627165267145,
        type: "reRoll",
        operator: null,
        application: {
          text: "Wound roll",
          value: "woundRoll",
          types: ["modify", "reRoll"],
          optionSets: ["modifyRoll", "reRoll"],
        },
        option: {
          text: "Re-roll ones",
          value: "reRollOnes",
          dataCySuffix: "ones",
        },
        condition: {
          event: null,
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "POISON:2",
    objectAbilities: [
      {
        description: "Always wound on a 2+ (unmodified) (excludes VEHICLE, TITANIC)",
        aliases: [],
        id: null,
        updated: 1627428020918,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "2",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: ["VEHICLE", "TITANIC"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "POISON:3",
    objectAbilities: [
      {
        description: "Always wound on a 3+ (unmodified) (excludes VEHICLE, TITANIC)",
        aliases: [],
        id: null,
        updated: 1627428059792,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "3",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: ["VEHICLE", "TITANIC"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "POISON:4",
    objectAbilities: [
      {
        description: "Always wound on a 4+ (unmodified) (excludes VEHICLE, TITANIC)",
        aliases: [],
        id: null,
        updated: 1627428091417,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "4",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: ["VEHICLE", "TITANIC"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "RADIUM",
    objectAbilities: [
      {
        description: "Hit roll of 6 (unmodified) : 1 auto-wound (excludes VEHICLE)",
        aliases: [],
        id: 1,
        updated: 1633945969124,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Auto-wound",
            value: "autoWounds",
            conditionEvents: ["hitRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        override: { type: null, outcome: null },
        keywords: { exclude: ["VEHICLE"], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "SNIPER",
    objectAbilities: [
      {
        description: "Wound roll of 6 (unmodified) : 1 mortal wound",
        aliases: [],
        id: 1,
        updated: 1631607294764,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Mortal wound(s)",
            value: "mortalWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "SHURIKEN",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set AP to -3",
        aliases: [],
        id: 1,
        updated: 1629891146814,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set AP",
            value: "setAp",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: null,
            operator: "replace",
          },
          value: -3,
          cap: null,
        },
        override: { type: null, outcome: null },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "TESLA",
    objectAbilities: [
      {
        description: "Hit roll of 6 (unmodified) : 2 extra hits",
        aliases: [],
        id: 1,
        updated: 1626709788023,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "hitRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Extra hit(s)",
            value: "extraHits",
            conditionEvents: ["hitRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "VEHICLE=DAMAGE:2",
    objectAbilities: [
      {
        description: "Set damage to 2 (VEHICLE only)",
        aliases: [],
        id: null,
        updated: 1627299632145,
        type: "setEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "unconditional",
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll", "unconditional"],
            valueMin: 1,
            operator: "replace",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "VEHICLE=DAMAGE:3",
    objectAbilities: [
      {
        description: "Set damage to 3 (VEHICLE only)",
        aliases: [],
        id: null,
        updated: 1627300202012,
        type: "setEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "unconditional",
          rollValue: null,
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll", "unconditional"],
            valueMin: 1,
            operator: "replace",
          },
          value: "3",
          cap: null,
        },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "VEHICLE=WOUND:4+",
    objectAbilities: [
      // Note: This is the same modifier that's mapped to "VEHICLE=WOUND:U:4+".
      {
        description: "Always wound on a 4+ (unmodified) (VEHICLE only)",
        aliases: [],
        id: null,
        updated: 1627513703521,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "4",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "VEHICLE=WOUND:U:4+",
    objectAbilities: [
      // Note: This is the same modifier that's mapped to "VEHICLE=WOUND:4+".
      {
        description: "Always wound on a 4+ (unmodified) (VEHICLE only)",
        aliases: [],
        id: null,
        updated: 1627513703521,
        type: "overrideReqs",
        operator: null,
        application: null,
        option: null,
        condition: {
          event: null,
          rollValue: "4",
          rollThreshold: "orGreater",
          rollState: "unmodified",
        },
        effect: { type: null, value: null, cap: null },
        override: { type: "alwaysConditional", outcome: "wound" },
        keywords: { exclude: [], include: ["VEHICLE"] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=+1MW",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : 1 mortal wound",
        aliases: [],
        id: null,
        updated: 1627165463825,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Mortal wound(s)",
            value: "mortalWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=AP:-3",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set AP to -3",
        aliases: [],
        id: null,
        updated: 1627165503509,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set AP",
            value: "setAp",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: null,
            operator: "replace",
          },
          value: -3,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=AP:-4",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set AP to -4",
        aliases: [],
        id: null,
        updated: 1627165529398,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set AP",
            value: "setAp",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: null,
            operator: "replace",
          },
          value: -4,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=AP:-6",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set AP to -6",
        aliases: [],
        id: null,
        updated: 1627165548813,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set AP",
            value: "setAp",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: null,
            operator: "replace",
          },
          value: -6,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=DAMAGE:2",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set damage to 2",
        aliases: [],
        id: null,
        updated: 1627165582693,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "replace",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6+=DAMAGE:3",
    objectAbilities: [
      {
        description: "Wound roll of 6+ : set damage to 3",
        aliases: [],
        id: null,
        updated: 1627165615756,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "orGreater",
          rollState: "modified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "replace",
          },
          value: "3",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6=+1MW",
    objectAbilities: [
      {
        description: "Wound roll of 6 (unmodified) : 1 mortal wound",
        aliases: [],
        id: null,
        updated: 1627165673151,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Mortal wound(s)",
            value: "mortalWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: 1,
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6=+2MW",
    objectAbilities: [
      {
        description: "Wound roll of 6 (unmodified) : 2 mortal wounds",
        aliases: [],
        id: null,
        updated: 1627165692140,
        type: "conditionalEffect",
        operator: "add",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Mortal wound(s)",
            value: "mortalWounds",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "add",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
  {
    stringAbility: "WOUND:6=DAMAGE:2",
    objectAbilities: [
      {
        description: "Wound roll of 6 (unmodified) : set damage to 2",
        aliases: [],
        id: null,
        updated: 1627165717799,
        type: "conditionalEffect",
        operator: "replace",
        application: null,
        option: null,
        condition: {
          event: "woundRoll",
          rollValue: "6",
          rollThreshold: "exact",
          rollState: "unmodified",
        },
        effect: {
          type: {
            text: "Set damage",
            value: "setDamage",
            conditionEvents: ["hitRoll", "woundRoll"],
            valueMin: 1,
            operator: "replace",
          },
          value: "2",
          cap: null,
        },
        keywords: { exclude: [], include: [] },
        scope: ["weapon"],
        attackType: null,
        profileRole: null,
        override: { type: null, outcome: null },
      },
    ],
  },
]
