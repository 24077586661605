import { regexDiceNotation } from "~/utils/update/migrations/0.54.0/regex"

export default function MeltaN(description) {
  const regex = new RegExp(
    `^\\+(${regexDiceNotation}) to damage \\(within half range\\)$`,
    "i"
  )
  const result = description.match(regex)
  if (result) {
    return {
      name: `Melta ${result[1]}`,
      type: "reserved",
    }
  }
  return null
}
