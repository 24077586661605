export default function effectReRoll(data) {
  const { effect, ellipsis } = data
  let description = ""

  if (effect.data.application) {
    description = "Re-roll "
    // Roll to re-roll.
    const rollToReRoll = effect.data.application.text
    // Singular or plural result.
    let s = ""

    // Limit.
    if (effect.data.limit === "single") {
      description += "one "
    }
    if (effect.data.limit === "none") {
      description += "all "
      s += "s"
    }

    // Result to re-roll.

    if (!effect.data.resultToReRoll) {
      description += ellipsis
    }
    // All possible failure(s)
    if (effect.data.resultToReRoll === "allPossibleFailures") {
      description += `possible failed ${rollToReRoll}${s}`
    }
    // Failed
    if (effect.data.resultToReRoll === "failed") {
      description += `failed ${rollToReRoll}${s}`
    }
    // Single.
    if (effect.data.resultToReRoll === "single") {
      description += `${rollToReRoll} result${s} of `
      if (effect.data.valueSingle) {
        description += effect.data.valueSingle
      } else {
        description += ellipsis
      }
    }
    // Range.
    if (effect.data.resultToReRoll === "range") {
      const min = effect.data.valueRange[0]
      const max = effect.data.valueRange[1]
      description += `${rollToReRoll} `
      if (min === max) {
        description += `result${s} of ${min}`
      } else {
        // NOTE: This uses an en dash, not a hyphen.
        // Credit: https://www.debbie-emmitt.com/2021/05/21/how-to-write-number-ranges-a-complete-guide/
        description += `result${s} of ${min}–${max}`
      }
    }
  }

  return {
    description,
  }
}
