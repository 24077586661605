import * as vt from "vue-toastification"
import "vue-toastification/dist/index.css"

const options = {
  position: "bottom-right",
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vt.default, options)
  return {
    provide: {
      toast: vt.useToast(),
    },
  }
})
