export default function effectOverrideReqs(data) {
  const { effect, ellipsis } = data
  let description = ""

  let overrideType = ""
  let postCondition = null

  if (effect.data.type) {
    overrideType = "Only"
    if (effect.data.type === "always") {
      overrideType = "Always"
    }
    description = `${overrideType} `
  }

  if (effect.data.type && !effect.data.outcome) {
    description += ellipsis
  }

  if (effect.data.type && effect.data.outcome) {
    description += effect.data.outcome

    if (effect.data.irrespective) {
      postCondition = "(irrespective of attack modifiers)"
    }

    if (effect.data.treatWoundAsUnmodified6) {
      postCondition = "(treat as unmodified 6)"
    }
  }

  return {
    description,
    postCondition,
  }
}
