import semverGt from "semver/functions/gt"
import semverEq from "semver/functions/eq"

// eslint-disable-next-line no-unused-vars
function logIfDev(updateVersion) {
  if (import.meta.env.VITE_APP_ENVIRONMENT === "dev") {
    // console.log(`Applying update ${updateVersion}... (apply pending updates)`)
  }
}

function applyUpdate(updateVersion, updateContext) {
  // Returns true if updateVersion is > versionToUpdateFrom,
  // OR applyLatestUpdate is set to true and updateVersion === appVersionLastSeen,
  // OR applyAllUpdates is set to true.

  if (semverGt(updateVersion, updateContext.versionToUpdateFrom)) {
    logIfDev(updateVersion)
    return true
  }

  if (
    updateContext?.applyLatestUpdate &&
    semverEq(updateVersion, updateContext.versionToUpdateFrom)
  ) {
    logIfDev(updateVersion)
    return true
  }

  if (updateContext?.applyAllUpdates) {
    logIfDev(updateVersion)
    return true
  }

  return false
}

export { applyUpdate }
