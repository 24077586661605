import { defineStore } from "pinia"
import { ref } from "vue"

export const usePageCrunchSingleStore = defineStore(
  "pageCrunchSingle",
  () => {
    const panelsCrunchSingle = ref([])

    return {
      panelsCrunchSingle,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: 86400, // 1 day in seconds
        sameSite: true,
      }),
    },
  }
)
